<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  async mounted() {
    await this.fetchSprints();
  },
  methods: {
    ...mapActions({
      fetchSprints: 'sprint/fetchSprints'
    })
  }
};
</script>

<style lang="scss">
.agile-content__container {
  @apply px-8 py-4;

  &.py-0 {
    @apply py-0;
  }
}
.agile-badge {
  @apply px-3 py-1 text-xs inline-flex items-center rounded-full overflow-hidden max-w-full;
  background-color: #f5f5f5;

  .dot-status {
    @apply flex-shrink-0;
  }

  &.badge--brand {
    @apply bg-brand-blue text-white;
  }

  &.badge--slim {
    @apply py-0;
  }
}

.sprint-table {
  @apply w-full border-collapse;
  thead {
    @apply border-white border;
  }
  thead th {
    @apply font-normal text-left text-gray-caption text-xs uppercase whitespace-no-wrap;

    &.text-center {
      @apply text-center;
    }
  }

  tbody tr:not(.tr--reset) {
    @apply cursor-pointer border;
    font-size: 14px;
    &:hover {
      @apply bg-light-gray transition-colors duration-200;
    }
    &:active {
      @apply bg-gray-main-dark-light;
    }
  }

  tr:not(.tr--reset) td,
  th {
    @apply px-2 align-top;

    &:first-of-type {
      @apply pl-6;
    }
    &:last-of-type {
      @apply pr-6;
    }
  }
  th {
    @apply py-2;
  }
  tr:not(.tr--reset) td {
    @apply py-4;
  }
}
</style>
